import React, { useEffect, useState, useRef } from 'react'
import '../../index.css'
import { generateUsername } from 'friendly-username-generator'
import { petTypeConstant, petBehaviourConstant } from '../../constants/dataConstant'
import BGHome from "../../assets/images/BgHome.png"
import BGImage2 from "../../assets/images/BGImage2.png"
import ButtonElement from '../../components/ButtonElement'
import Dog from "../../assets/images/Dog.png"
import Cat from "../../assets/images/Cat.png"
import Fish from "../../assets/images/Fish.png"
import Rabbit from "../../assets/images/Rabbit.png"
import Others from "../../assets/images/Others.png"
import DogCurious from "../../assets/images/DogCurious.png"
import DogScared from "../../assets/images/DogScared.png"
import DogEnergetic from "../../assets/images/DogEnergetic.png"
import DogSocial from "../../assets/images/DogSocial.png"
import CatCurious from "../../assets/images/CatCurious.png"
import CatScared from "../../assets/images/CatScared.png"
import CatEnergetic from "../../assets/images/CatEnergetic.png"
import CatSocial from "../../assets/images/CatSocial.png"
import FishCurious from "../../assets/images/FishCurious.png"
import FishScared from "../../assets/images/FishScared.png"
import FishSocial from "../../assets/images/FishSocial.png"
import FishEnergetic from "../../assets/images/FishEnergetic.png"
import RabbitCurious from "../../assets/images/RabbitCurious.png"
import RabbitEnergetic from "../../assets/images/RabbitEnergetic.png"
import RabbitScared from "../../assets/images/RabbitScared.png"
import RabbitSocial from "../../assets/images/RabbitSocial.png"
import OthersCurious from "../../assets/images/OthersCurious.png"
import OthersEnergetic from "../../assets/images/OthersEnergetic.png"
import OthersScared from "../../assets/images/OthersScared.png"
import OthersSocial from "../../assets/images/OthersSocial.png"
import Forward from "../../assets/images/Forward.png"
import Backward from "../../assets/images/Backward.png"
import Lottie from "lottie-react";
import petPlaylistAnimation from "../../assets/animations/Animation - 1731334310172.json"
import petCowAnimation from "../../assets/animations/Animation - 1731334342148.json"
import Dekstop from './Dekstop'
import Mobile from './Mobile'
import useLogin from '../../hooks/useLogin'
import ShareModal from '../../components/Share'
import DogHappy from "../../assets/images/DogHapppy.png"
import CatHappy from "../../assets/images/CatHappy.png"
import FishHappy from "../../assets/images/FishHappy.png"
import OthersHappy from "../../assets/images/OthersHappy.png"
import RabbitHappy from "../../assets/images/RabbitHappy.png"
import useClevertap from '../../hooks/useClevertap'
import clevertap from 'clevertap-web-sdk'
import ReactGA from 'react-ga4';


const playersData = [
  {
    "image": DogHappy,
    "pet": "DOG",
    "behaviours": [
      { "behaviour": "HAPPY", "image": DogHappy },
      { "behaviour": "CALM", "image": Dog },
      { "behaviour": "ENERGETIC", "image": DogEnergetic },
      { "behaviour": "SOCIAL", "image": DogSocial },
      { "behaviour": "SCARED", "image": DogScared },
      { "behaviour": "ANXIOUS", "image": DogScared },
      { "behaviour": "CURIOUS", "image": DogCurious },
    ]
  },
  {
    "image": CatHappy,
    "pet": "CAT",
    "behaviours": [
      { "behaviour": "HAPPY", "image": CatHappy },
      { "behaviour": "CALM", "image": Cat },
      { "behaviour": "ENERGETIC", "image": CatEnergetic },
      { "behaviour": "SOCIAL", "image": CatSocial },
      { "behaviour": "SCARED", "image": CatScared },
      { "behaviour": "ANXIOUS", "image": CatScared },
      { "behaviour": "CURIOUS", "image": CatCurious },
    ]
  },
  {
    "image": FishHappy,
    "pet": "FISH",
    "behaviours": [
      { "behaviour": "HAPPY", "image": FishHappy },
      { "behaviour": "CALM", "image": Fish },
      { "behaviour": "ENERGETIC", "image": FishEnergetic },
      { "behaviour": "SOCIAL", "image": FishSocial },
      { "behaviour": "SCARED", "image": FishScared },
      { "behaviour": "ANXIOUS", "image": FishScared },
      { "behaviour": "CURIOUS", "image": FishCurious },
    ]
  },
  {
    "image": RabbitHappy,
    "pet": "RABBIT",
    "behaviours": [
      { "behaviour": "HAPPY", "image": RabbitHappy },
      { "behaviour": "CALM", "image": Rabbit },
      { "behaviour": "ENERGETIC", "image": RabbitEnergetic },
      { "behaviour": "SOCIAL", "image": RabbitSocial },
      { "behaviour": "SCARED", "image": RabbitScared },
      { "behaviour": "ANXIOUS", "image": RabbitScared },
      { "behaviour": "CURIOUS", "image": RabbitCurious },
    ]
  },
  {
    "image": OthersHappy,
    "pet": "OTHERS",
    "behaviours": [
      { "behaviour": "HAPPY", "image": OthersHappy },
      { "behaviour": "CALM", "image": Others },
      { "behaviour": "ENERGETIC", "image": OthersEnergetic },
      { "behaviour": "SOCIAL", "image": OthersSocial },
      { "behaviour": "SCARED", "image": OthersScared },
      { "behaviour": "ANXIOUS", "image": OthersScared },
      { "behaviour": "CURIOUS", "image": OthersCurious },
    ]
  }
]
const HomePage = () => {
  const { handleGoogleSignIn, googleLogout, accessToken, uuid,
    setUserCreated, userFirstName, userInfo, username } = useLogin()

  const [isOpen, setIsOpen] = useState(false);
  const [petName, setPetName] = useState('')
  const [petType, setPetType] = useState(petTypeConstant[0])
  const [petBehaviour, setPetBehaviour] = useState(petBehaviourConstant[0])
  const [typeOfPlaylist, setTypeOfPlaylist] = useState('')
  const [generatedPlaylist, setGeneratedPlaylist] = useState([])
  const [youtubeLink, setYoutubeLink] = useState('')
  const [playlistLink, setPlaylistLink] = useState('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [level, setLevel] = useState(0)
  const [activePetIndex, setActivePetIndex] = useState(0);
  const [activeBehaviorIndex, setActiveBehaviorIndex] = useState(0);
  const [gameStart, setGameStart] = useState("ready")
  const currentPet = playersData[activePetIndex];
  const currentBehavior = currentPet.behaviours[activeBehaviorIndex];
  const [reveal, setReveal] = useState(false)
  const [playlistId, setPlaylistId] = useState('')
  const { handleEventPushClick, onUserLogin } = useClevertap();

  const createPlaylistRef = useRef<HTMLDivElement>(null)
  const iframeRef = useRef<HTMLDivElement>(null)

  const generatePlaylistFromOpenAI = async () => {
    setIsGenerating(true)
    try {
      const response = await fetch('https://cruncher.asset.money/pet/generate-playlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          pet_name: petName,
          pet_type: currentPet.pet,
          pet_behaviour: currentBehavior.behaviour,
          type_of_playlist: typeOfPlaylist
        })
      })

      if (!response.ok) {
        throw new Error('Failed to generate playlist from OpenAI')
      }

      const data = await response.json()
      // console.log('Playlist generated:', data)
      setGeneratedPlaylist(data?.playlist)
    } catch (error) {
      console.error('Error generating playlist from OpenAI:', error)
    } finally {
      setIsGenerating(false)
    }
  }

  const handleShare = () => {
    if (accessToken) {
      setIsOpen(true);
    }
    handleEventPushClick("PET_PLAYLIST_SHARE", {
      email: userInfo?.email
    });

    ReactGA.event({
      category: 'PP Share cta',
      action: 'Click',
      label: 'PET_PLAYLIST_SHARE',
    });

  }

  const handleNextPet = () => {
    setActivePetIndex((prevIndex) => (prevIndex + 1) % playersData.length);
    setActiveBehaviorIndex(0); // Reset behavior index for the new pet
  };

  const handlePreviousPet = () => {
    setActivePetIndex((prevIndex) =>
      prevIndex === 0 ? playersData.length - 1 : prevIndex - 1
    );
    setActiveBehaviorIndex(0); // Reset behavior index for the new pet
  };

  const handleNextBehavior = () => {
    setActiveBehaviorIndex((prevIndex) =>
      (prevIndex + 1) % currentPet.behaviours.length
    );
  };

  const handlePreviousBehavior = () => {
    setActiveBehaviorIndex((prevIndex) =>
      prevIndex === 0 ? currentPet.behaviours.length - 1 : prevIndex - 1
    );
  };

  const createPlaylist = async (uuid: string, email: string) => {
    setIsCreating(true)
    try {
      let username = ''
      const usernameFromLocalStorage = localStorage.getItem('username')
      if (!usernameFromLocalStorage) {
        username = generateUsername()
        localStorage.setItem('username', username)
      } else {
        username = usernameFromLocalStorage
      }

      const response = await fetch('https://cruncher.asset.money/pet/create-playlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid: uuid ?? "",
          email: email,
          username: username,
          playlist_description: `${petName} is a ${currentPet.pet} and is ${currentBehavior.behaviour}.`,
          pet_name: petName,
          pet_type: currentPet.pet,
          pet_behaviour: currentBehavior.behaviour,
          type_of_playlist: typeOfPlaylist,
          playlist: generatedPlaylist
        })
      })

      if (!response.ok) {
        throw new Error('Failed to create playlist')
      }

      const data = await response.json()
      // console.log('Playlist created:', data)
      let link = data?.createdPlaylist?.external_urls?.spotify
      let youtubeLink = data?.youtubePlaylistId
      setTimeout(() => {
        setPlaylistId(data?.playlistId)
        setPlaylistLink(link)
        setYoutubeLink(youtubeLink)
      }, 1500)
    } catch (error) {
      console.error('Error creating playlist:', error)
    } finally {
      setIsCreating(false)
    }
  }

  const updatePlaylist = async (uuid: string, email: string, playlistId: string) => {
    try {
      const response = await fetch('https://cruncher.asset.money/pet/update-playlist', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ uuid, email, playlistId })
      })
    } catch (error) {
      console.error('Error updating playlist:', error)
    }
  }

  const handleSubmit = async () => {
    handleEventPushClick(`PET_PLAYLIST_GENERATE`, {
      typeofPlaylist: typeOfPlaylist
    });
    ReactGA.event({
      category: 'PP generate playlist cta',
      action: 'Click',
      label: "PET_PLAYLIST_GENERATE",
    });

    await generatePlaylistFromOpenAI()
    setTimeout(() => {
      createPlaylistRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  const handleCreatePlaylist = async () => {
    setLevel((prevLevel) => {
      const nextLevel = prevLevel + 1;
      return nextLevel; // Update the state to the new level
    });

    handleEventPushClick("PET_PLAYLIST_SPOTIFY_CREATE", {
      email: userInfo?.email
    });

    ReactGA.event({
      category: 'PP spotify signup CTA',
      action: 'Click',
      label: 'PET_PLAYLIST_SPOTIFY_CREATE',
  });

    await createPlaylist(uuid, userInfo?.email || "");
  };


  const handleNextLevel = () => {
    const nextlevel = level
    if (level === 4) {
      handleSubmit()
    }
    else if (level === 2) {
      handleEventPushClick("PET_PLAYLIST_SELECT_PET", {
        petType: currentPet.pet
      });
      ReactGA.event({
        category: 'PP Select Pet',
        action: 'Click',
        label: `PET_PLAYLIST_SELECTED_PET ${currentPet.pet}`,
      });

    }
    else if (level === 3) {
      handleEventPushClick("PET_PLAYLIST_SELECT_BEHAVIOUR", {
        petBehaviour: currentBehavior.behaviour
      });
      ReactGA.event({
        category: 'PP Select behaviour',
        action: 'Click',
        label: `PET_PLAYLIST_SELECTED_BEHAVIOUR ${currentPet.pet}`,
      });
    }

    setLevel(nextlevel + 1)
  }

  // Add this useEffect to handle scrolling after playlist link is set
  useEffect(() => {
    if (playlistLink) {
      setTimeout(() => {
        iframeRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [playlistLink])

  const handleNextStep = () => {
    setGameStart("set");
    handleEventPushClick("PET_PLAYLIST_LETS_GO", {});
    setLevel(1)
    handleEventPushClick(`PET_PLAYLIST_ENTER_PET_NAME`, {
      level: level,
      petName: petName
    });
    ReactGA.event({
      category: 'PP Enter pet name',
      action: 'Click',
      label: `PET_PLAYLIST_ENTERED_PET_NAME ${petName}`,
    });

    setTimeout(() => {
      setGameStart("go");
    }, 2000);
  };

  const tryNewPlaylist = () => {
    setLevel(4)
    handleEventPushClick("PET_PLAYLIST_TRY_NEW_PLAYLIST", { email: userInfo?.email })
    ReactGA.event({
      category: 'PP try new playlist CTA',
      action: 'Click',
      label: 'PET_PLAYLIST_TRY_NEW_PLAYLIST',
  });
  }

  const handleSignIn = async () => {
    try {
      const result = await handleGoogleSignIn()
      if (result?.uid) {
        // console.log("result", result)
        clevertap.profile.push({
          Site: {
            Name: result?.displayName || "",
            Email: result?.email,
          },
        });
        await updatePlaylist(result?.uid, result?.email || "", playlistId)
        handleEventPushClick("PETPLAYLIST_SIGNUP", { email: result?.email })
        ReactGA.event({
          category: 'PP signup CTA',
          action: 'Click',
          label: 'PETPLAYLIST_SIGNUP',
      });
        onUserLogin(result)
        setReveal(false)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (!accessToken) {
      setReveal(true);
    } else {
      setReveal(false);
    }
  }, [accessToken]);

  const handleClose = () => {
    setIsOpen(false);
  };
  // console.log("checing", uuid)

  return (
    <>
      <div className='md:hidden h-screen'>
        <Mobile tryNewPlaylist={tryNewPlaylist} typeOfPlaylist={typeOfPlaylist} setLevel={setLevel} handleShare={handleShare} accessToken={accessToken} handleSignIn={handleSignIn} reveal={reveal} isCreating={isCreating} playlistLink={playlistLink} iframeRef={iframeRef} handleCreatePlaylist={handleCreatePlaylist} gameStart={gameStart} handleNextStep={handleNextStep} generatedPlaylist={generatedPlaylist} level={level} youtubeLink={youtubeLink} petName={petName} currentPet={currentPet} isGenerating={isGenerating} setPetName={setPetName} handlePreviousPet={handlePreviousPet} handleNextPet={handleNextPet} currentBehavior={currentBehavior} handlePreviousBehavior={handlePreviousBehavior} handleNextBehavior={handleNextBehavior} setTypeOfPlaylist={setTypeOfPlaylist} handleNextLevel={handleNextLevel} />
      </div>
      <div className='hidden md:block h-screen'>
        <Dekstop tryNewPlaylist={tryNewPlaylist} typeOfPlaylist={typeOfPlaylist} setLevel={setLevel} handleShare={handleShare} accessToken={accessToken} handleSignIn={handleSignIn} reveal={reveal} isCreating={isCreating} playlistLink={playlistLink} iframeRef={iframeRef} handleCreatePlaylist={handleCreatePlaylist} gameStart={gameStart} handleNextStep={handleNextStep} generatedPlaylist={generatedPlaylist} youtubeLink={youtubeLink} level={level} petName={petName} currentPet={currentPet} isGenerating={isGenerating} setPetName={setPetName} handlePreviousPet={handlePreviousPet} handleNextPet={handleNextPet} currentBehavior={currentBehavior} handlePreviousBehavior={handlePreviousBehavior} handleNextBehavior={handleNextBehavior} setTypeOfPlaylist={setTypeOfPlaylist} handleNextLevel={handleNextLevel} />
      </div>
      {isOpen && (
        <ShareModal
          shareUrl={`https://petplaylist.xyz/share/${encodeURIComponent(playlistLink)}/${petName}`}
          artistName={""}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default HomePage
